<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />

    <!-- <section class="header p-2" algin="center">
      <center>
        <img
          class="revivify-logo"
          height="55px"
          :src="require('@/assets/images/logo/new_logo.png')"
          alt="Revivify Logo"
        />
      </center>
    </section> -->
    
    <b-container fluid id="seller_tab" class="bg_light revivify_guide">
      <b-row>
        <b-col>
          <div class="p-10">
            <b-tabs pills card vertical class="tab_heading">
              <b-tab title="SELL WITH US +" active
                ><b-card-text>
                  <h1 class="tab-11">OPEN YOUR SELLER ACCOUNT</h1>
                  <p>
                    To start selling, you will first need to create your seller
                    account. For this we ask you to provide your details and
                    also link to Stripe Connect in order to make prompt payments
                    once your item sells.
                  </p>
                  <h1 class="tab-1 mt-3">SUBMIT YOUR LISTING</h1>
                  <p>
                    Next, you will be prompted to submit your listing by
                    <b>uploading your item images</b> in keeping with the stated
                    guidelines. For this, you will be required to
                    <b>describe your item</b>, including its designer name and
                    if possible, an estimate year of purchase and original
                    purchase price. If you have more items to sell, simply click
                    "add item", or continue to
                    <b>submit your piece for approval.</b> A member of our team
                    will then get back to you within <b>24 hours</b> to let you
                    know if your item has been approved along with a suggested
                    selling price.<br /><br />
                    We understand that you may be worried about how you can
                    safely get your items to our studio. The great news is –
                    <b>collection is on us!</b> Meaning all you need to do is
                    make sure it is securely packaged up, and our trusted
                    delivery partner will do the rest.<br /><br />
                    In advance of your upload, we recommend referring to our
                    list of
                    <a
                      href="../../designers/"
                      class="seller_link"
                      target="_blank"
                      >accepted brands.</a
                    >
                  </p>
                  <h1 class="tab-2 mt-3">Await authentication and valuation</h1>
                  <p>
                    We will
                    <b>notify you via email to confirm your items</b> have
                    reached us. <br /><br />
                    Next, our expert team will physically authenticate your
                    item(s) and communicate with you for final approval. Kindly
                    note, if the physical piece differs from the listed
                    submission, the piece will be re valued or rejected. The
                    seller will be required to arrange collection of the piece
                    within 5 working days incase of rejection.<br /><br />
                    Once an agreement has been made,<b>
                      our in-house professionals will photograph </b
                    >your pieces and launch your listing.
                  </p>
                  <h1 class="tab-3 mt-3">Start selling and earning</h1>
                  <p>
                    The Revivify team takes care of the entire selling process
                    from beginning to end, so you can get back to what's
                    important to you. For those who like to keep informed, our
                    unique platform and dashboard allow you to track your items,
                    providing an overview of those which are currently listed,
                    sold, and finalized.
                  </p>
                </b-card-text></b-tab
              >
              <b-tab title="SELLER PAYMENTS +">
                <h1 class="tab-4">Seller Payments</h1>
                <p class="seller_Pay">
                  For every successful sale,<br />
                  Revivify keeps <b>35%</b> of the valued item price as the
                  commission.
                </p>
                <b-card-text> </b-card-text>
              </b-tab>
              <b-tab title="VALUATION +"
                ><b-card-text
                  ><h1 class="tab-5">VALUATION</h1>
                  <p>
                    We understand that, for first-time sellers, pricing can feel
                    like a minefield. With Revivify, our team is with you every
                    step of the way. Once you have added your item to your
                    seller account, along with your own clear imagery, our team
                    will get in touch within 24 hours to confirm pricing. If you
                    are happy, delivery will then be arranged with our trusted
                    courier ready for authentication and professional
                    photography at our studio prior to listing.
                  </p>
                </b-card-text></b-tab
              >
              <b-tab title="SELLER DASHBOARD +"
                ><b-card-text>
                  <h1 class="tab-6">SELLER DASHBOARD</h1>
                  <p>
                    Once you create your seller account, you will have 24/7
                    access to your unique dashboard, where you can manage your
                    notifications and messages with the Revivify concierge. Here
                    you will also be able to view past sales, potential
                    earnings, and track the status of your items.<br /><br />
                    Along with keeping you informed throughout the seller
                    journey, from approval to sold, you will also have the
                    chance to boost your pieces for increased visibility to
                    increase the speed of your sale. As items are added to our
                    inventory, your listing will naturally move further down our
                    listings. This advanced promotional tool allows you to
                    position your item back to the top pages 2 times through the
                    year for better visibility. Explore more by creating your
                    seller account.<br /><br />Whenever you make a sale, you can
                    use our innovative, one-click invoice generation button to
                    receive payment almost instantly.
                  </p></b-card-text
                ></b-tab
              >
              <b-tab title="HOLDING YOUR ITEM +"
                ><b-card-text
                  ><h1 class="tab-7">HOLDING YOUR ITEM</h1>
                  <p>
                    While we will hold your item at Revivify HQ for one year
                    before shipping it back to you (in case of an unsuccessful
                    sale), we understand that there may be an occasion during
                    the period your item is with us that you wish to make use of
                    your item. To have your item returned to you and delisted,
                    simply click its checkout button via your seller dashboard.
                    Please note that you will be responsible for the shipment
                    pick up and cost, in addition to a 5% charge of the listing
                    price to cover Revivify for collection, product photography
                    and other costs borne. To relist your item, you can log back
                    into your dashboard at any time and reactivate your listing.
                  </p>
                </b-card-text></b-tab
              >

              <b-tab title="BRAND WE ACCEPT +"
                ><b-card-text
                  ><h1 class="tab-9">Brands We Accept</h1>
                  <p>
                    We know that many of our clients will be seeking a specific
                    piece by a specific designer, which is why we accept an
                    extensive
                    <b>
                      <a
                        href="https://www.revivify.com/designers/"
                        class="seller_link"
                        target="_blank"
                        >list of brands
                      </a></b
                    >
                    at Revivify. <br /><br />
                    However, as we always want to ensure that we offer the best
                    curated edit from each, never flooding the site with repeat
                    items, or those out of season, please understand that every
                    piece by every brand will be approved on a case-by-case
                    basis.
                  </p>
                </b-card-text></b-tab
              >
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-row>
      <b-col class="text-center mt-2">
        <a href="/" >Back to Home</a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  BRow,
  BCol,
  BContainer,
  BLink,
  BButton,
  BTab,
  BTabs,
  BCard,
  BCardText,
  BForm,
} from "bootstrap-vue";

export default {
  metaInfo: {
    title: "Seller Guide | Revivify",
    htmlAttrs: {
      lang: "en-US",
    },
  },
  components: {
    BRow,
    BCol,
    BContainer,
    BLink,
    BButton,
    BTab,
    BTabs,
    BCardText,
    BForm,
    AppCollapse,
    AppCollapseItem,
    Loading,
  },
};
</script>

<style >
/* html {
  scroll-behavior: smooth !important;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.modal-header {
  justify-content: center;
  padding: 1rem;
}
.modal-header .close {
  display: none !important;
}
.modal-header .modal-title {
  font-size: 1.5rem;
}
.modal-body {
  padding: 1rem 3rem;
}
.modal-body label {
  font-size: 1.2rem;
}
.modal-body input {
  font-size: 1.2rem;
  border-radius: 0;
}
.modal-footer {
  justify-content: center !important;
}
.modal-footer button {
  padding: 1rem 3rem;
  border-radius: 0;
}
.modal-footer .btn-primary {
  background-color: #3a4b43 !important;
  border-color: #3a4b43 !important;
}
.modal-body .input-group-text {
  border-radius: 0;
}
.seller_form_container {
  width: 70%;
}
.banner-text {
  margin-top: 2.5rem;
}
.banner-text p {
  font-size: 2rem;
  word-spacing: 2px;
  color: #ad9c8d;
  margin-bottom: 1.3rem;
  line-height: 1.3;
}
.banner-text h1 {
  font-size: 2rem;
  font-weight: 900;
  color: #3a4b43;
}
.seller_form {
  background: transparent;
  font-size: 1.5rem;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #8e928f;
  border-radius: 0;
  padding: 1.5rem 0.5rem;
}
.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
  border-radius: 0;
}

.seller_form::placeholder {
  color: #9da09d;
}
.seller_form:focus {
  box-shadow: none !important;
  background: transparent !important;
}
.btn-seller {
  border-radius: 0 !important;
  outline: 0;
  padding: 1.4rem 4rem;
  background-color: #3a4b43 !important;
  color: #d8cfc6 !important;
}
.seller_text {
  font-size: 1.4rem;
  color: #3a4b43;
  margin-bottom: 2rem;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0px;
  width: 1.9rem;
  height: 1.9rem;
  background: transparent;
  border: 2px solid #3a4b43;
}
.custom-checkbox .custom-control-label::after {
  width: 1.9rem;
  height: 1.9rem;
}
.custom-checkbox .custom-control-label {
  font-size: 1.4rem;
  color: #3a4b43;
  padding-left: 1.5rem;
} */
.seller_link {
  color: #3a4b43;
  font-weight: 900;
  text-decoration: underline;
  letter-spacing: 1px;
}
.p-10 {
  padding: 2rem 0;
}
.bg_light {
  background: #fff !important;
  border-radius: 1rem;
}
.revivify_guide .card-header {
  background: #fff !important;
  padding-right: 4rem;
  padding-top: 0.2rem;
}
.revivify_guide .tab-10 .card-header {
  padding-top: 0%;
}
.revivify_guide .nav-pills .nav-link {
  letter-spacing: 2px;
  padding-left: 0%;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  justify-content: start;
  padding-bottom: 3px;
}
.revivify_guide .nav-pills .nav-link.active {
  border-top: 0;
  box-shadow: none;
  background-color: transparent;
  color: #3a4b43;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  font-weight: 700;
  padding-bottom: 3px;
  letter-spacing: 2px;
  border-bottom: 1px solid #636780;
  display: inline-block;
}

.tab_heading h1 {
  font-weight: 900;
  color: #3a4b43;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tab_heading .card-text p {
  font-size: 1.3rem;
  color: #3a4b43;
  line-height: 1.5;
  text-align: justify;
}
.revivify_guide .card-body {
  padding: 0 2rem 5rem 7rem;
  border-left: 1px solid #3a4b43;
}
.revivify_guide .tab_heading .tab-11::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/frETIUs.jpeg);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-1::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/Vof3njK.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-2::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/y5GPj5r.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-3::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/462x9zQ.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-4::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/TINjDd4.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-5::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/qJDRQkL.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-6::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/8efNhFs.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-7::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/0wYxfXK.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-8::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/SGSPn7P.png);
  background-size: cover;
  top: -10px;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-9::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/7QJ2XNv.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .tab_heading .tab-10::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url(https://i.imgur.com/7QJ2XNv.png);
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.revivify_guide .seller_Pay {
  font-size: 1.3rem;
  color: #3a4b43;
  line-height: 1.5;
  text-align: justify;
}
.revivify_guide .tab-10 .card {
  box-shadow: none !important;
}
.revivify_guide .tab-10 .card-body {
  border-left: 0 !important;
  font-size: 1.1rem !important;
}
.revivify_guide .tab-10 .card-header {
  padding-bottom: 0 !important;
}
.revivify_guide .tab-10 .collapse-title {
  font-size: 1.6rem !important;
  font-weight: 800 !important;
  letter-spacing: 1.5px !important;
  line-height: 1.4 !important;
  padding-bottom: 0 !important;
}
.revivify_guide .tab-10 .pl-2 {
  padding: 0 !important;
}

.revivify_guide .collapse-icon [data-toggle="collapse"]:after {
  background-image: none !important;
}

section.header {
  background-color: white;
}
.seller_country {
  background-color: #7fffd400;
  background: #7fffd400 !important;
  border: 0px;
  border-bottom: 2px solid #8e928f;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  /* font-size: larger; */
  height: 3.2rem;
  font-size: 1.5rem;
  color: #9da09d;
}

.seller_country:focus {
  box-shadow: none !important;
}
.eye_icon .input-group-text {
  background: transparent;
  border: 0;
  border-bottom: 2px solid #8e928f;
  border-radius: 0;
}
.revivify_guide .collapse-margin .card {
  margin-bottom: 1.71rem !important;
  margin-top: 0 !important;
}
.revivify_guide .collapse-margin .card .card-header {
  padding-top: 0 !important;
}
.text_p {
  font-size: 1.3rem !important;
  color: #3a4b43 !important;
  line-height: 1.5 !important;
}

/* .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0;
  padding: 0.5rem 2.2rem;
  background: #3a4b43;
}
.forgot_btn {
  margin-top: 2px;
}
.tel_input_main .phone_input input {
  padding-left: 85px;
}
.tel_input_main .code_input {
  font-size: 1.5rem;
  color: #6e6b7b !important;
  position: absolute;
  top: 0;
  width: 85px;
}
.vs--open .vs__dropdown-toggle {
  box-shadow: none !important;
}

.tel_input_main .code_input .vs__dropdown-toggle {
  border: none;
  padding: 0;
}
.tel_input_main .code_input .vs__dropdown-toggle .vs__actions {
  display: none !important;
}
.tel_input_main .code_input .vs__search::placeholder {
  color: #9da09d;
}
.tel_input_main .code_input .vs__search {
  margin-top: 2px;
}
.vs__dropdown-menu {
  min-width: 350px !important;
}
.arrow_btn_fq a {
  padding: 15px 10px;
  background: #3a4b43;
  border-radius: 50%;
  margin: 4px;
}
.arrow_btn svg {
  width: 30px;
  height: 30px;
  color: #fff;
}
.arrow_btn_fq {
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  z-index: 99999;
} */

/*--------------------------- max-width 992px Start---------------------- */

@media (max-width: 992px) {
  .seller_form_container {
    padding: 0 3rem;
  }
  .p-10 {
    padding: 3rem 1rem;
  }
  .revivify_guide .card-body {
    padding: 0px 1rem 0 5rem;
  }
  .revivify_guide .card-text h1 {
    text-align: left;
  }
}

/*--------------------------- max-width 992px End ---------------------- */

/*--------------------------- max-width 768px ---------------------- */
@media (max-width: 768px) {
  .revivify_guide .tab-content {
    margin-left: 5rem;
  }
  .revivify_guide .tab_heading h1 {
    font-size: 1.5rem;
  }
  .revivify_guide .col-auto {
    width: 100%;
  }
  /* .banner-text p {
    font-size: 1.5rem;
  }
  .banner-text h1 {
    font-size: 1.5rem;
  } */
}

/*--------------------------- max-width 768px ---------------------- */

/*--------------------------- max-width 418px ---------------------- */
@media (max-width: 576px) {
  .revivify-logo {
    height: 42px;
  }
  .revivify_guide .tab-content {
    margin-left: 3rem;
  }
  .revivify_guide .card-body {
    padding: 0px 1rem 0 4rem;
  }
  .p-10 {
    padding: 3rem 0;
  }

  .revivify_guide .nav-pills .nav-link.active {
    font-size: 1rem;
  }
  .revivify_guide .nav-pills .nav-link {
    font-size: 1rem;
  }
  .revivify_guide .card-header {
    padding-left: 0;
  }
  .revivify_guide .tab_heading .card-text p {
    font-size: 1rem;
  }
  .tab_heading .tab-11::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-1::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-2::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-3::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-4::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-5::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-6::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-7::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-8::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-9::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading .tab-10::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .revivify_guide .tab_heading h1 {
    font-size: 1.3rem;
  }
  .revivify_guide .seller_Pay {
    font-size: 1rem;
  }
}

/*--------------------------- max-width 418px end ---------------------- */
</style>
